export enum OpenGraphMetaTags {
  title = 'og:title',
  type = 'og:type',
  url = 'og:url',
  image = 'og:image',
  image_width = 'og:image:width',
  image_height = 'og:image:height',
  site_name = 'og:site_name',
  description = 'og:description',
  country_name = 'og:country-name',
  region = 'og:region',
  locality = 'og:locality',
  email = 'og:email',
  phone_number = 'og:phone_number',
  fb_page_id = 'fb:page_id',
  locale = 'og:locale',
  locale_alternative = 'og:locale:alternate',
  fax_number = 'og:fax_number',
  street_address = 'og:street-address',
  postal_code = 'og:postal-code',
  points = 'og:points',
  video = 'og:video',
  video_width = 'og:video_width',
  video_height = 'og:video_height',
  audio = 'og:audio',
  audio_type = 'og:audio_type',
  audio_title = 'og:audio_title',
  audio_artist = 'og:audio_artist',
  audio_album = 'og:audio_album',
  twitter_card = 'twitter:card',
  twitter_site = 'twitter:site',
  twitter_creator = 'twitter:creator',
  language = 'content-language'
}
