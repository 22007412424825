import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OpenGraphMetaTags } from '@core/enums/meta/open-graph-meta-tags.enum';
import { BasicMetaTags } from '@core/enums/meta/basic-meta-tags.enum';
import { environment } from '@environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Language } from '@core/enums/generic/language.enum';
import { LanguageUtils } from '@core/utils/language.utils';
import { ConfigService } from '@core/services/config.service';

@Injectable({ providedIn: 'root' })
export class SeoService {
  private renderer: Renderer2;

  constructor(
    private meta: Meta,
    private title: Title,
    private rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
    public configService: ConfigService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  updateBasicMeta(basicMeta: Partial<Record<keyof typeof BasicMetaTags, string>>) {
    Object.keys(BasicMetaTags).forEach(key => {
      if (basicMeta[key]) {
        this.meta.updateTag({ name: BasicMetaTags[key], content: basicMeta[key] });
      } else {
        this.meta.removeTag(`name="${BasicMetaTags[key]}"`);
      }
    });
  }

  updateOnlyGraphMeta(openGraphMetaTags: Partial<Record<keyof typeof OpenGraphMetaTags, string>>) {
    Object.keys(OpenGraphMetaTags).forEach(key => {
      if (openGraphMetaTags[key]) {
        this.meta.updateTag({ name: OpenGraphMetaTags[key], content: openGraphMetaTags[key] });
      }
    });
  }

  updateGraphMeta(openGraphMetaTags: Partial<Record<keyof typeof OpenGraphMetaTags, string>>) {
    Object.keys(OpenGraphMetaTags).forEach(key => {
      if (openGraphMetaTags[key]) {
        this.meta.updateTag({ name: OpenGraphMetaTags[key], content: openGraphMetaTags[key] });
      } else {
        this.meta.removeTag(`name="${OpenGraphMetaTags[key]}"`);
      }
    });
  }

  updateTitle(item: string): void {
    this.title.setTitle(item);
  }

  disableIndexing(): void {
    this.meta.updateTag({ name: 'robots', content: 'noindex' });
  }

  setSeoMetaTags(language: string, key: string, image: string): void {
    const { metaTags } = this.configService.config;

    if (!metaTags[language] || !metaTags[language][key]) return;

    this.updateTitle(metaTags[language][key]?.title);
    this.updateGraphMeta({
      title: metaTags[language][key]?.title,
      description: metaTags[language][key]?.text,
      image: `${environment.landingUrl}/${image}`,
      language,
    });
    this.updateBasicMeta({
      language,
      description: metaTags[language][key]?.text,
    });
  }

  setLangAttributes(routerUrl: string): void {
    Object.keys(Language).forEach(language => {
      const existentLink = this.document.querySelector(`#${language}-alternate`);
      const linkElt = existentLink || this.renderer.createElement('link');
      const urlLanguage = LanguageUtils.languageSeo(routerUrl);
      const url = routerUrl.replace(`/${urlLanguage}/`, '');

      if (isPlatformBrowser(this.platformId)) {
        this.renderer.setAttribute(linkElt, 'id', `${language}-alternate`);
        this.renderer.setAttribute(linkElt, 'rel', 'alternate');
        this.renderer.setAttribute(linkElt, 'hreflang', language);
        this.renderer.setAttribute(linkElt, 'href', `${this.configService.config.domain}/${language}/${url}`);

        if (!existentLink) {
          this.renderer.appendChild(this.document.head, linkElt);
        }
      }
    })
  }

  setCanonicalLink(routerUrl: string): void {
    const url = `${this.configService.config.domain}${routerUrl}/`;

    const existentLink = this.document.querySelector(`#canonical-link`);
    const linkElt = existentLink || this.renderer.createElement('link');

    if (isPlatformBrowser(this.platformId)) {
      this.renderer.setAttribute(linkElt, 'id', `canonical-link`);
      this.renderer.setAttribute(linkElt, 'rel', 'canonical');
      this.renderer.setAttribute(linkElt, 'href', url);

      if (!existentLink) {
        this.renderer.appendChild(this.document.head, linkElt);
      }
    }
  }
}
