export enum BasicMetaTags {
  title = 'title',
  keywords = 'keywords',
  description = 'description',
  subject = 'subject',
  copyright = 'copyright',
  language = 'language',
  robots = 'robots',
  revised = 'revised',
  abstract = 'abstract',
  topic = 'topic',
  summary = 'summary',
  Classification = 'Classification',
  author = 'author',
  designer = 'designer',
  reply_to = 'reply-to',
  owner = 'owner',
  url = 'url',
  identifier_URL = 'identifier-URL',
  directory = 'directory',
  category = 'category',
  coverage = 'coverage',
  distribution = 'distribution',
  rating = 'rating',
  revisit_after = 'revisit-after'
}
